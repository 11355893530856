<template>
  <article class="data-load" :class="{ 'sk-loading': isLoading }">
    <h2>{{ $t("pages.permission.data") }}</h2>

    <sk-spinner v-if="isLoading"></sk-spinner>

    <div class="row" v-if="isInitialized">
      <div class="col text-right">
        <a href="#" class="secondary-button mx-4" @click.prevent="goBack">
          <img src="@/assets/IC_Arrow_Left_pink_secondary.svg" alt="back" />
          <span class="back-link">{{ $t("components.actions.back") }}</span>
        </a>
      </div>
    </div>

    <div class="row" v-if="isInitialized && detail.id">
      <div class="col">
        <suc-text-form-show-field :title="$t('pages.permission.absences.id')" :value="detail.id" />
      </div>
    </div>
    <div class="row" v-if="isInitialized && detail.isPermission()">
      <div class="col">
        <suc-text-form-show-field
          :title="$t('pages.permission.status')"
          :value="permissionStatus"
        />
      </div>
    </div>
    <div class="row" v-if="isInitialized">
      <div class="col">
        <suc-text-form-show-field
          :title="$t('pages.permission.absences.type_absenses')"
          :value="detail.causeOfAbsenceDescription"
        />
      </div>
    </div>
    <div class="row" v-if="isInitialized">
      <div class="col">
        <suc-text-form-show-field
          :title="$t('pages.permission.absences.start_date')"
          :value="detail.dateStart"
        >
          <template #default="props">
            <formatted-date-component :val="props.value" :withTime="true" />
          </template>
        </suc-text-form-show-field>
      </div>
      <div class="col">
        <suc-text-form-show-field
          :title="$t('pages.permission.absences.end_date')"
          :value="detail.dateEnd"
        >
          <template #default="props">
            <formatted-date-component :val="props.value" :withTime="true" />
          </template>
        </suc-text-form-show-field>
      </div>
    </div>
    <div class="row" v-if="isInitialized && detail.comments">
      <div class="col">
        <suc-text-form-show-field
          :title="$t('pages.permission.absences.comments')"
          :value="detail.comments"
        />
      </div>
    </div>
    <div class="row" v-if="isInitialized">
      <div class="col">
        <div class="form-group">
          <label class="label-description"> {{ $t("pages.permission.attachments") }}</label>
          <div>
            <div class="description">
              <a
                target="_blank"
                href="#"
                v-for="attachment in detail.attachments"
                :key="attachment.key"
                @click.prevent="downLoadAttachment(attachment)"
                >{{ attachment.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="detail.canRemove()">
      <div class="col-md-12 text-right">
        <vue-button
          class="btn-danger button my-1 mx-2"
          :title="$t('components.actions.remove')"
          @click.prevent="removeAbsence"
        >
          <template #body-icon>
            <i class="fas fa-trash-alt"></i>
          </template>
        </vue-button>
      </div>
    </div>
  </article>
</template>

<script>
import { SucTextFormShowField, VueButton } from "@/components/form";
import { ModalAlert } from "@/components/modal";
import { FormattedDateComponent } from "@/components/formatted";
import { FinalAbsence } from "@/pages/components/data";
import SkSpinner from "@/components/SkSpinner";

import { mapGetters } from "vuex";

import { SHOW_CONFIRM_INFO_MODAL } from "@/pages/components";

import {
  ABSENCE_SHOW_EDIT_EVENT,
  ABSENCE_SHOW_CREATE_EVENT,
  ABSENCE_UPDATED_EVENT,
  ABSENCE_CLICKED_EVENT,
} from "./index";
import * as Sentry from "@sentry/vue";

import api from "@/api/appointment";

export default {
  components: {
    SucTextFormShowField,
    FormattedDateComponent,
    VueButton,
    SkSpinner,
  },
  data() {
    return {
      isLoading: false,
      detail: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["employeeCode"]),
    /**
     * For now can´t edit absence
     */
    canEdit() {
      return false;
    },
    permissionStatus() {
      if (this.detail.isAbsenceRejected()) return this.$t("pages.permission.absences.rejected");
      else if (this.detail.isAbsenceNeedMeeting())
        return this.$t("pages.permission.absences.need_meeting");
      else return this.$t("pages.permission.absences.confirm_waiting");
    },
    isInitialized() {
      return this.$isset(this.detail);
    },
  },
  created() {
    EventBus.listen(ABSENCE_CLICKED_EVENT, (absence) => {
      this.getDetail(absence.id, absence.sourceCode);
    });
  },
  beforeDestroy() {},
  methods: {
    getDetail(id, source) {
      this.isLoading = true;
      this.detail = null;
      api
        .getDetailAbsence({ employeeCode: this.employeeCode, id, source })
        .then((response) => {
          this.detail = new FinalAbsence().parse(response.data);
        })
        .catch((error) => {
          if (error.message.toLowerCase() === "network error") {
            this.$toasts.error(this.$t("components.messages.network_error"));
          } else if (error.response.status == 404) {
            EventBus.fire(ABSENCE_UPDATED_EVENT);
          } else {
            //unsigned error
            Sentry.captureException(error);
            this.$toasts.error(this.$t("components.messages.error"));
          }
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    goBack() {
      EventBus.fire(ABSENCE_SHOW_CREATE_EVENT);
    },
    goEdit() {
      EventBus.fire(ABSENCE_SHOW_EDIT_EVENT, this.detail);
    },
    downLoadAttachment(attachment) {
      this.isLoading = true;
      api
        .downloadAttachment({
          employeeCode: attachment.employeeCode,
          reference: attachment.reference,
        })
        .then((response) => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${attachment.name}.${attachment.fileExtension}`;
          link.click();
          this.isLoading = false;
        })
        .catch((error) => {
          ModalAlert.error({ message: "Error" });
          console.error(error.response);
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    removeAbsence() {
      if (this.detail.canRemove()) {
        EventBus.fire(SHOW_CONFIRM_INFO_MODAL, {
          message: this.$t("pages.permission.delete_confirmation"),
          action: async () => {
            await api.removeAppointment(this.detail.id);
            //need wait for updating data on server
            await new Promise((r) => setTimeout(r, 6000));

            EventBus.fire(ABSENCE_UPDATED_EVENT);
            EventBus.fire(ABSENCE_SHOW_CREATE_EVENT);
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.button {
  border-radius: $border-radius;
  height: 50px;
}

.secondary-button {
  color: $color-secondary;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
}
</style>
